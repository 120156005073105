import { render, staticRenderFns } from "./pickup.vue?vue&type=template&id=45031c36&scoped=true&lang=pug&"
import script from "./pickup.vue?vue&type=script&lang=ts&"
export * from "./pickup.vue?vue&type=script&lang=ts&"
import style0 from "../components/styles/pickup.styl?vue&type=style&index=0&id=45031c36&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45031c36",
  null
  
)

export default component.exports