<template lang="pug">
    section#link.section
        div.section_body
            h1.page_title Service

            div.service_box
                a.service_link(href="http://ele-soku.zsw.jp")
                    span.service_title えれくとりっく速報 | えれ速
                    span.service_body PCパーツとかそのへんっぽい方のニュースのまとめサイトをまとめてるアンテナサイト

            div.service_box
                a.service_link(href="http://aqua-antenna.zsw.jp")
                    span.service_title Aqua-Antenna | 水速
                    span.service_body なんとなく家庭的っぽい感じというか生活感のあるというか、そんな感じの5chまとめサイトをまとめてるアンテナサイト

            div.service_box.blank
            div.service_box.blank
            div.service_box.blank
            div.service_box.blank

</template>

<style scoped lang="stylus" src="../components/styles/service.styl"></style>

<script lang="ts">
export default {}
</script>
