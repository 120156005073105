<template lang="pug">
    section#menu
</template>

<style scoped lang="stylus">
</style>

<script lang="ts">
export default {}
</script>
